<template>
  <div class="bg-white">
    <header-image class="header-height mb-0 mx-0">
      <img src="@/assets/img/whoweare/banner-top.jpg" alt="Header picture" class="relative rounded-0" width="auto" height="auto"/>
      <h1 class="header-text">{{ $t('whoweare') }}</h1>
    </header-image>
    
    <section class="padding-center">
      <h2 class="text-black">{{ appName }},</h2>
      <p class="pb-3">{{ $t('aboutUsSection.paragraph_1') }}</p>
      <p>{{ $t('aboutUsSection.paragraph_2') }}</p>
      <p class="bold">{{ $t('aboutUsSection.paragraph_3') }}</p>
    </section>

    <section class="d-md-flex">
      <img
        loading="lazy"
        src="@/assets/img/whoweare/point01.jpg"
        alt="slider image"
        class="col-12 col-md-6 p-0 m-0 rounded-0"
      />
      <div class="col-12 col-md-6 padding-center">
        <h3>01.</h3>
        <p>{{ $t('aboutUsSection.point_01') }}</p>
      </div>
    </section>

    <section class="d-md-flex">
      <img
        loading="lazy"
        src="@/assets/img/whoweare/point02.jpg"
        alt="slider image"
        class="col-12 col-md-6 order-md-2 col-md-6 p-0 rounded-0"
      />
      <div class="col-12 col-md-6 order-md-1 order-md-1 col-md-6 padding-center">
        <h3>02.</h3>
        <p>{{ $t('aboutUsSection.point_02') }}</p>
      </div>
    </section>

    <section class="d-md-flex">
      <img
        loading="lazy"
        src="@/assets/img/whoweare/point03.jpg"
        alt="slider image"
        class="col-12 col-md-6 p-0 rounded-0"
      />
      <div class="col-12 col-md-6 padding-center">
        <h3>03.</h3>
        <p>{{ $t('aboutUsSection.point_03') }}</p>
      </div>
    </section>
    
    <section>
      <div class="padding-center margin-center d-flex justify-content-center align-items-center">
        <p>
          {{ $t('aboutUsSection.paragraph_4_1') }}
          <span class="bold">{{ $t('aboutUsSection.paragraph_4_2') }}</span>
          <span class="bold d-block">{{ $t('aboutUsSection.paragraph_4_3') }}</span>
        </p>
      </div>
    </section>

    <section class="p-4 p-md-2">
      <div class="p-4 p-md-5 d-sm-flex flex-wrap gap-2 justify-content-around">
        <div class="container-icons my-5 m-md-2">
          <div class="icon mx-auto">
            <img
              loading="lazy"
              src="@/assets/img/whoweare/icon1.png"
              alt="24 horas"
              class="col-12"
            />
          </div>
          <p class="textIcon">{{ $t('aboutUsSection.icon_text_1') }}</p>
        </div>

        <div class="container-icons my-5 m-md-2">
          <div class="icon mx-auto">
            <img
              loading="lazy"
              src="@/assets/img/whoweare/icon2.png"
              alt="todas las citas en un mismo lugar"
              class="col-12"
            />
          </div>
          <p class="textIcon">{{ $t('aboutUsSection.icon_text_2') }}</p>
        </div>

        <div class="container-icons my-5 m-md-2">
          <div class="icon mx-auto">
            <img
              loading="lazy"
              src="@/assets/img/whoweare/icon3.png"
              alt="Gestiona agendas"
              class="col-12"
            />
          </div>
          <p class="textIcon">{{ $t('aboutUsSection.icon_text_3') }}</p>
        </div>
        
        <div class="container-icons my-5 m-md-2">
          <div class="icon mx-auto">
            <img
              loading="lazy"
              src="@/assets/img/whoweare/icon4.png"
              alt="Envía recordatorios"
              class="col-12"
            />
          </div>
          <p class="textIcon">{{ $t('aboutUsSection.icon_text_4') }}</p>
        </div>
      </div>
    </section>

    <header-image class="header-height m-0">
      <img loading="lazy" src="@/assets/img/whoweare/banner-bottom.jpg" alt="Header picture" class="relative rounded-0" width="auto" height="auto"/>
      <h1 class="footer-text-1">{{ $t('createdByHairdressers') }}</h1>
      <h1 class="footer-text-2">{{ $t('forHairdressers') }}</h1>
    </header-image>

    <section class="padding-center section_5">
      <div class="d-flex justify-content-center align-items-center">
        <p>
          {{ $t('aboutUsSection.paragraph_5_1') }}
          <span class="bold">{{ $t('aboutUsSection.paragraph_5_2') }}</span>
          {{ $t('aboutUsSection.paragraph_5_3') }}
        </p>
      </div>

      <section class="mt-4 d-md-flex flex-wrap">
        <div class="container-number-color col-12 col-md-6">
          <span>01.</span>
          <p>{{ $t('aboutUsSection.number_color_point_01') }}</p>
        </div>
        <div class="container-number-color col-12 col-md-6">
          <span>02.</span>
          <p>{{ $t('aboutUsSection.number_color_point_02') }}</p>
        </div>
        <div class="container-number-color col-12 col-md-6">
          <span>03.</span>
          <p>{{ $t('aboutUsSection.number_color_point_03') }}</p>
        </div>
        <div class="container-number-color col-12 col-md-6">
          <span>04.</span>
          <p>{{ $t('aboutUsSection.number_color_point_04') }}</p>
        </div>
        <div class="container-number-color col-12 col-md-6">
          <span>05.</span>
          <p>{{ $t('aboutUsSection.number_color_point_05') }}</p>
        </div>
        <div class="container-number-color col-12 col-md-6">
          <span>06.</span>
          <p>{{ $t('aboutUsSection.number_color_point_06') }}</p>
        </div>
      </section>

    </section>

    <section class="pt-2 pb-5 px-5 mx-auto mt-4">
      <p class="text-center mb-3">{{ $t('aboutUsSection.start_now_to_focus_on_what_matters_most') }}</p>

      <div class="d-flex justify-content-center mb-5">
        <base-button
          type="success"
          class="block col col-sm-10 col-md-6 col-lg-4"
          size="lg"
          @click="$router.push('/service-contract')">
           <span>{{ $t('contract') }} {{ appName }}</span>
        </base-button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  BaseButton,
  HeaderImage
} from "@/components/index";

export default {
  data() {
    return {
      appName: process.env.VUE_APP_AS_NAME
    }
  },
  components: {
    BaseButton,
    HeaderImage
  },
  computed: { ...mapState(["defaultTitle"]) },
  metaInfo() {
    return {
      title: `${this.$t('vueMeta.whoWeAre.title', [this.defaultTitle])}`,
      meta: [
        {name: 'og:title', property: 'og:title', content:  `${this.$t('vueMeta.whoWeAre.title', [this.defaultTitle])}`},
        {name: 'og:type', property: 'og:type', content: 'website'},
        {name: 'og:url', property: 'og:url', content: `${process.env.VUE_APP_URL.substring(0, process.env.VUE_APP_URL.length - 1)}${this.$route.fullPath}`},
        {name: 'og:description', property: 'og:description', content: this.$t('vueMeta.whoWeAre.description')},
        {name: 'description', content: this.$t('vueMeta.whoWeAre.description')},
        {name: 'og:site_name', property: 'og:site_name', content: process.env.VUE_APP_AS_NAME}
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/white-dashboard.scss';
  section {
    h2, h3, p {
      color: black!important;
      display: block;
      font-family: $font-family-base;
    }
    h2 {
      font-weight: bolder;
      font-size: 2.8rem;
      margin-bottom: 0;
    }
    h3 {
      font-weight: bolder;
      font-size: 7rem;
      margin-bottom: 0;
    }
    p {
      font-weight: 400;
      font-size: 0.9rem;
      &.textIcon{
        font-size: 0.75rem;
      }
    }
    .bg-white {
      background: white;
    }
    .padding-center {
      padding: 4rem;
      margin: auto;
    }
    
    &.padding-center {
      padding: 4rem;
      margin: auto;
    }
  }
  .bold {
    font-weight: bold;
  }
  .icon {
    width: 5.5rem;
    height: 5.5rem;
  }

  .container-number-color {
    span {
      color: $secondary;
      font-size: 1.5rem;
      font-weight: bold;
    }
    p {
      font-size: 0.8rem;
    }
  }

  .section_5 {
    max-width: 1000px;
  }
  @media (min-width: 576px) {
    .container-icons {
      width: 45%;
    }
  }

  @media (min-width: 768px) {
    .margin-center {
      margin-left: 6rem!important;
      margin-right: 6rem!important;
    }
    section {
      .padding-center {
        padding: 5rem;
        margin: auto;
      }
      &.padding-center {
        padding: 8rem;
        margin: auto;
      }
    } 
    .container-icons {
      width: 20%;
    }
  }
</style>

<style lang="scss">
@import '../../assets/scss/white-dashboard.scss';
.header-text, .footer-text-1, .footer-text-2 {
  color: white!important;
  font-family: $font-family-base;
  font-size: 2rem!important;
  font-weight: 300;
  position: absolute;
  text-align: center;
  width: 100%;
}
.header-text {
  top: 15vh;
}
.footer-text-1 {
  top: 11vh;
}
.footer-text-2 {
  top: 19vh;
}
.header-height {
  img {
    width: auto!important;
    height: 300px!important;
    object-fit: cover;
  }
  width: auto!important;
  height: 300px!important;
}
@media (min-width: 576px) {
  .header-text {
    font-size: 2.8rem;
  }
  .header-height {
    width: auto!important;
    height: auto!important;
  }
}
</style>